import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
  NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
  NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
  dateModeOptions = [
    Enums.dateModes.currentMonth,
    Enums.dateModes.lastMonth,
    Enums.dateModes.lastThirtyDays,
    Enums.dateModes.previousThreeMonths,
    Enums.dateModes.previousTwelveMonths
  ]
  showEntireOrgHierarchyForAllUsers = false;
  defaultMonthDateMode: DateMonthModes = 'calendar';
  filterTypeLookup: { [id: string]: FilterType } = {
    'org': { displayName: 'Hierarchy', name: 'org' },
    'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
    'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
    'device-type': { displayName: 'Device Type', name: 'device-type' },
    'channel': { displayName: 'Channel', name: 'channel' },
    'inventory-status': { displayName: 'Inventory Status', name: 'inventory-status' },
    'inventory-types': { displayName: 'Inventory Type', name: 'inventory-types' },
    'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
    'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
    'source-type': { displayName: 'Source Type', name: 'source-type' },
    'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
    'vehicle-model': { displayName: 'Model', name: 'vehicle-model' },
    'vehicle-models': { displayName: 'Models', name: 'vehicle-models' },
    'vehicle-series': { displayName: 'Series', name: 'vehicle-series' },
    'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
    'website-provider': { displayName: 'Provider', name: 'website-provider' },
    'website-metric-type': { displayName: 'Metric', name: 'website-metric-type' },
    'service-scheduler-provider': { displayName: 'Provider', name: 'service-scheduler-provider' }
  };

  filterReportConfigs: { [id: string]: FilterConfigReport } = {
    // Digital Advertising v5
    'DigitalAdvertisingDashboard': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: false,
      reportName: constants.reportNames.digitalAdvertisingDashboard,
      filters: ['org', 'channel', 'digad-provider' ],
      defaults: ['org', 'channel', 'digad-provider'],
      section: 'digital-advertising',
      orgTypes: [1, 2]
    },
    'WebsiteOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.websiteOverview,
      filters: ['org', 'channel', 'website-provider', 'device-type'],
      defaults: ['org', 'channel', 'website-provider', 'device-type'],
      section: 'website',
      orgTypes: [1, 2]
    },
    //// Digital Retailing
    //'DigitalRetailingOverview': {
    //  reportName: constants.reportNames.digitalRetailingOverview,
    //  dateMode: Enums.dateModes.currentMonth,
    //  filters: ['org', 'device-type'],
    //  defaults: ['org', 'device-type'],
    //  section: 'provider-tools',
    //  orgTypes: [1, 4]
    //},
    //// Trade-In
    //'TradeInOverview': {
    //  reportName: constants.reportNames.tradeInOverview,
    //  dateMode: Enums.dateModes.currentMonth,
    //  filters: ['org', 'device-type'],
    //  defaults: ['org'],
    //  section: 'provider-tools',
    //  orgTypes: [1, 4]
    //},
    //// Service Scheduler
    //'ServiceSchedulerOverview': {
    //  reportName: constants.reportNames.serviceSchedulerOverview,
    //  dateMode: Enums.dateModes.currentMonth,
    //  filters: ['org', 'device-type', 'service-scheduler-provider'],
    //  defaults: ['org'],
    //  section: 'provider-tools',
    //  orgTypes: [1, 4]
    //},
    //// Chat
    //'ChatOverview': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  restrictDates: true,
    //  reportName: constants.reportNames.chatOverview,
    //  filters: ['org', 'device-type'],
    //  defaults: ['org', 'device-type'],
    //  section: 'chat',
    //  orgTypes: [1, 4]
    //},
    //'ChatSummary': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  reportName: constants.reportNames.chatSummary,
    //  filters: [
    //    'org'
    //  ],
    //  defaults: [
    //    'org'
    //  ],
    //  section: 'chat',
    //  orgTypes: [1]
    //},
    //// Inventory
    //'InventoryOverview': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  restrictDates: false,
    //  reportName: constants.reportNames.inventoryOverview,
    //  filters: [
    //    'org', 'vehicle-series', 'vehicle-models', 'inventory-status', 'inventory-types'
    //  ],
    //  defaults: [
    //    'org',
    //  ],
    //  section: 'inventory'
    //},
    //// Leads
    //'LeadsOverview': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  restrictDates: true,
    //  reportName: constants.reportNames.leadsOverview,
    //  filters: ['org'],
    //  defaults: ['org'],
    //  section: 'leads'
    //},
    //'LeadSummary': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  reportName: constants.reportNames.leadSummary,
    //  filters: [
    //    'org', 'lead-type', 'source-type'
    //  ],
    //  defaults: [
    //    'org', 'lead-type', 'source-type'
    //  ],
    //  section: 'leads',
    //  orgTypes: [1]
    //},
    //'LeadSourcePerformance': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  reportName: constants.reportNames.leadSourcePerformance,
    //  filters: [
    //    'org', 'lead-type', 'source-type', 'vehicle-make'
    //  ],
    //  defaults: [
    //    'org', 'lead-type', 'source-type'
    //  ],
    //  section: 'leads',
    //  orgTypes: [1]
    //},
    //'LeadEventPerformance': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  restrictDates: true,
    //  reportName: constants.reportNames.leadEventPerformance,
    //  filters: [
    //    'org', 'vehicle-make'
    //  ],
    //  defaults: [
    //    'org'
    //  ],
    //  section: 'leads',
    //  orgTypes: [1]
    //},
    //'FacebookPerformance': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  restrictDates: true,
    //  reportName: constants.reportNames.facebookPerformance,
    //  filters: [
    //    'org'
    //  ],
    //  defaults: [
    //    'org'
    //  ],
    //  section: 'leads',
    //  orgTypes: [1]
    //},
    //'LeadVehiclePerformance': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  reportName: constants.reportNames.leadVehiclePerformance,
    //  filters: [
    //    'org', 'source-type', 'vehicle-make'
    //  ],
    //  defaults: [
    //    'org', 'source-type'
    //  ],
    //  section: 'leads',
    //  orgTypes: [1]
    //},
    //'LeadTransactions': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  reportName: constants.reportNames.leadTransactions,
    //  filters: [
    //    'org', 'lead-type', 'source-type'
    //  ],
    //  defaults: [
    //    'org', 'lead-type', 'source-type'
    //  ],
    //  section: 'leads',
    //  orgTypes: [1]
    //},
    //// Sales
    //'SalesOverview': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  restrictDates: true,
    //  reportName: constants.reportNames.salesOverview,
    //  filters: ['org'],
    //  defaults: ['org'],
    //  section: 'sales',
    //  orgTypes: [1]
    //},
    //'SalesSummary': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  restrictDates: true,
    //  reportName: constants.reportNames.salesSummary,
    //  filters: [
    //    'org', 'sale-type', 'lead-type', 'source-type', 'vehicle-class'
    //  ],
    //  defaults: [
    //    'org', 'sale-type', 'lead-type', 'source-type'
    //  ],
    //  section: 'sales',
    //  orgTypes: [1]
    //},
    //'SalesVehicleSummary': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  restrictDates: true,
    //  reportName: constants.reportNames.salesVehicleSummary,
    //  filters: [
    //    'org', 'lead-type', 'sale-type', 'source-type'
    //  ],
    //  defaults: [
    //    'org', 'lead-type', 'sale-type', 'source-type'
    //  ],
    //  section: 'sales',
    //  orgTypes: [1]
    //},
    //'SalesForecastingSummary': {
    //    dateMode: Enums.dateModes.currentMonth,
    //    restrictDates: true,
    //    reportName: constants.reportNames.salesForecastingSummary,
    //    filters: [
    //      'org', 'sale-type', 'vehicle-model'
    //    ],
    //    defaults: [
    //      'org', 'sale-type'
    //    ],
    //    section: 'sales',
    //    orgTypes: [1]
    //},
    // Website
    //'WebsiteOverview': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  restrictDates: true,
    //  reportName: constants.reportNames.websiteOverview,
    //  filters: ['org'],
    //  defaults: ['org'],
    //  section: 'website',
    //  orgTypes: [1, 4]
    //},
    //'WebsiteAfterSales': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  reportName: constants.reportNames.websiteAfterSales,
    //  filters: [
    //    'org', 'device-type'
    //  ],
    //  defaults: [
    //    'org', 'device-type'
    //  ],
    //  section: 'website',
    //  orgTypes: [1, 4]
    //},
    //'WebsiteCertifiedChat': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  reportName: constants.reportNames.websiteCertifiedChat,
    //  filters: [
    //    'org', 'chat-provider'
    //  ],
    //  defaults: [
    //    'org', 'chat-provider'
    //  ],
    //  section: 'website',
    //  orgTypes: [1, 4]
    //},
    //'WebsiteLandingPage': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  reportName: constants.reportNames.websiteLandingPage,
    //  filters: [
    //    'org', 'website-provider'
    //  ],
    //  defaults: [
    //    'org', 'website-provider'
    //  ],
    //  section: 'website',
    //  orgTypes: [1, 4]
    //},
    //'WebsiteProviderSummary': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  reportName: constants.reportNames.websiteProviderSummary, // org only
    //  filters: ['org'],
    //  defaults: ['org'],
    //  section: 'website',
    //  orgTypes: [1, 4]
    //},
    //'WebsiteProviderScorecard': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  restrictDates: true,
    //  reportName: constants.reportNames.websiteProviderScorecard, // metric only
    //  filters: [],
    //  defaults: [],
    //  section: 'website',
    //  orgTypes: [1, 4]
    //},
    //'WebsiteReferrerSummary': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  reportName: constants.reportNames.websiteReferrerSummary,
    //  filters: [
    //    'org', 'device-type'
    //  ],
    //  defaults: [
    //    'org', 'device-type'
    //  ],
    //  section: 'website',
    //  orgTypes: [1, 4]
    //},
    //'WebsiteScorecard': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  restrictDates: true,
    //  reportName: constants.reportNames.websiteScorecard,
    //  filters: [
    //    'org', 'website-provider'
    //  ],
    //  defaults: [
    //    'org', 'website-provider'
    //  ],
    //  section: 'website',
    //  orgTypes: [1, 4]
    //},
    //'WebsiteSummary': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  reportName: constants.reportNames.websiteSummary,
    //  filters: [
    //    'org', 'website-provider'
    //  ],
    //  defaults: [
    //    'org', 'website-provider'
    //  ],
    //  section: 'website',
    //  orgTypes: [1, 4]
    //},
    //'WebsiteVehicleSummary': {
    //  dateMode: Enums.dateModes.currentMonth,
    //  reportName: constants.reportNames.websiteVehicleSummary,
    //  filters: [
    //    'org', 'website-provider', 'device-type'
    //  ],
    //  defaults: [
    //    'org', 'website-provider', 'device-type'
    //  ],
    //  section: 'website',
    //  orgTypes: [1, 4]
    //}
  };

  FILTER_CONFIG: { [id: string]: Filter } = {
    'org': {
      type: 'org',
      selected: [],
      removable: false,
      lockable: true,
      locked: true
    },
    'vehicle': {
      type: 'vehicle',
      selected: [],
      removable: false,
      lockable: true,
      locked: true
    },
    'channel': {
      type: 'channel',
      available: [2, 4, 5, 6, 8],
      selected: [
        { value: 2, display: 'Paid Search' },
        { value: 4, display: 'Paid Display' },
        { value: 5, display: 'Paid Video' },
        { value: 6, display: 'Paid Email' },
        { value: 8, display: 'Paid Social' },
      ],
      removable: true,
      lockable: true,
      locked: false
    },
    'chat-provider': {
      type: 'chat-provider',
      available: [15, 12, 2, 5, 17, 18, 19, 20],
      selected: [
        { value: 15, display: 'ActiveEngage' },
        { value: 12, display: 'CarNow' },
        { value: 2, display: 'Conversations' },
        { value: 5, display: 'Dealer eProcess' },
        { value: 17, display: 'Gubagoo' },
        { value: 18, display: 'LivePerson' },
        { value: 19, display: 'Podium' },
        { value: 20, display: 'Edmonds' },

      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'device-type': {
      type: 'device-type',
      selected: [
        { value: 1, display: 'Desktop' },
        { value: 2, display: 'Tablet' },
        { value: 3, display: 'Phone' }
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'inventory-status': {
      type: 'inventory-status',
      selected: [],
      removable: true,
      lockable: true,
      locked: false,
    },
    'inventory-types': {
      type: 'inventory-types',
      selected: [],
      removable: true,
      lockable: true,
      locked: false,
    },
    'lead-type': {
      type: 'lead-type',
      selected: [{ value: 1, display: 'New Vehicle' }],
      removable: false,
      lockable: true,
      locked: false
    },
    'sale-type': {
      type: 'sale-type',
      selected: [
        { value: 2, display: 'New' },
        { value: 1, display: 'CPO' }
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'service-scheduler-provider': {
      type: 'service-scheduler-provider',
      available: [8, 26, 37, 38, 39, 40, 41, 42, 50, 43, 45, 47],
      selected: [
          { value: 8, display: 'Affinitiv Book' },
          { value: 26, display: 'CDK Global' },
          { value: 37, display: 'Dealer FX' },
          { value: 38, display: 'DealerSocket' },
          { value: 39, display: 'eLead' },
          { value: 40, display: 'Reynolds & Reynolds' },
          { value: 41, display: 'Time Highway' },
          { value: 42, display: 'Total Customer Connect' },
          { value: 50, display: 'Update Promise' },
          { value: 43, display: 'xtime' },
          { value: 45, display: 'MyKaarma' },
          { value: 47, display: 'Dealerlogix' },
      ],
      removable: true,
      lockable: true,
      locked: false
    },
    'source-type': {
      type: 'source-type',
      selected: [
        { value: 1, display: 'Brand' },
        { value: 3, display: 'Dealer Website' },
        { value: 5, display: 'Event' }
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'vehicle-make': {
      type: 'vehicle-make',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'vehicle-class': {
      type: 'vehicle-class',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'vehicle-model': {
      type: 'vehicle-model',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'vehicle-models': {
      type: 'vehicle-models',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'vehicle-series': {
      type: 'vehicle-series',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'website-provider': {
      type: 'website-provider',
      available: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      selected: [
        { value: 1, display: 'SM360' },
        { value: 2, display: 'AutoSync' },
        { value: 3, display: 'eDealer' },
        { value: 4, display: 'Fox Dealer' },
        { value: 5, display: 'LeadBox' },
        { value: 6, display: 'Magnetis' },
        { value: 7, display: 'Vicimus' },
        { value: 8, display: 'Flex Dealer' },
        { value: 9, display: 'D2C' },
        { value: 10, display: 'AutoVerify' },
        { value: 11, display: 'CarPages' },
        { value: 12, display: 'Groupe Saillant' },
        { value: 13, display: 'Birchwood' }
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'website-provider-scorecard': {
      type: 'website-metric-type',
      available: [57, 11, 14, 2],
      selected: [
        { value: 57, display: 'Dealer Enrollments' },
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'digad-provider': {
      type: 'digad-provider',
      available: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
      selected: [
        { value: 1, display: 'SM360' },
        { value: 2, display: 'AutoSync' },
        { value: 3, display: 'eDealer' },
        { value: 4, display: 'Fox Dealer' },
        { value: 5, display: 'LeadBox' },
        { value: 6, display: 'Magnetis' },
        { value: 7, display: 'Vicimus' },
        { value: 8, display: 'Flex Dealer' },
        { value: 9, display: 'D2C' },
        { value: 10, display: 'AutoVerify' },
        { value: 11, display: 'CarPages' },
        { value: 12, display: 'Groupe Saillant' },
        { value: 13, display: 'Birchwood' },
        { value: 14, display: 'Smedia' },
        { value: 15, display: 'Stream Companies' },
      ],
      removable: true,
      lockable: true,
      locked: false
    },
  };
}
