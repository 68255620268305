import {
  IDigAdOverviewBillboardConfig,
  IDigAdOverviewConfig,
  IDefaultTableColumnConfig,
  IDigAdOverviewKpiConfig,
  ICardHeaderConfig,
  IDigAdOverviewChannelCardConfig,
  IDigAdOverviewTrafficTypeCardConfig
} from '../../../../_shared/models/models-index';

import * as constants from '../../../../_shared/constants/constants';
export class DigAdOverviewConfig implements IDigAdOverviewConfig {

  public reportTitle: string = 'Digital Advertising Overview';
  public chartSeriesColors = ['#000', '#737373', '#A6A6A6', '#D9D9D9', '#CCCCCC'];

  public providerPerformanceCardHeader: ICardHeaderConfig = {
    title: 'Provider Performance',
    iconClass: 'fa-tasks',
    helpTextKey: constants.helpTextKeys.digAdOverviewProviderPerformanceHelp,
    helpTextTitle: 'Provider Performance',
  };
  public dealerPerformanceCardHeader: ICardHeaderConfig = {
    title: 'Retailer Performance',
    iconClass: 'fa-suitcase',
    helpTextKey: constants.helpTextKeys.digAdOverviewDealerPerformanceHelp,
    helpTextTitle: 'Retailer Performance',
  };
  public trafficTrendCardHeader: ICardHeaderConfig = {
    title: 'Traffic Trend',
    iconClass: 'fa-bar-chart',
    helpTextKey: constants.helpTextKeys.digAdOverviewTrafficTrendHelp,
    helpTextTitle: 'Traffic Trend',
  };
  public channelPerformanceCardHeader: ICardHeaderConfig = {
    title: 'Channel Performance',
    iconClass: 'fa-adjust',
    helpTextKey: constants.helpTextKeys.digAdOverviewChannelPerformanceHelp,
    helpTextTitle: 'Channel Performance',
  };
  public trafficTypeCardHeader: ICardHeaderConfig = {
    title: 'Organic vs Paid',
    iconClass: 'fa-adjust',
    helpTextKey: constants.helpTextKeys.digAdOverviewTrafficTypeHelp,
    helpTextTitle: 'Traffic Type',
  };

  public channelCardConfig: IDigAdOverviewChannelCardConfig = {
    channelColors: ['#c51230', '#9e2a75', '#5f1675', '#6b45cc', '#0f4ec4', '#266886', '#369e8d', '#209645', '#587e35', '#ccc145', '#cc8b45', '#666666'],
    dimensionPropertyName: 'displayName',
    primaryMetricPropertyName: 'visits',
    secondaryMetricPropertyName: 'visitsWithEngagements'
  };

  public trafficTypeCardConfig: IDigAdOverviewTrafficTypeCardConfig = {
    headerConfig: {
      title: 'Organic vs Paid',
      iconClass: 'fa-adjust',
      helpTextKey: constants.helpTextKeys.digAdOverviewTrafficTypeHelp,
      helpTextTitle: 'Traffic Type',
    },
    chartConfig: {
      colors: ['#000', '#737373'],
      paidPropertyName: 'visits',
      organicPropertyName: 'organicWebsiteVisits'
    }
  }



  public billboards: IDigAdOverviewBillboardConfig[] = [
    {
      title: 'Impressions',
      subtitle: null,
      subtitleOne: 'impressionsPreviousMTD',
      subtitleTwo: 'impressionsPreviousMonth',
      iconClass: 'fa-user-plus fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'impressions',
      metricMOMPropertyName: 'impressionsMOM',
      metricYOYPropertyName: 'impressionsYOY',
      metricPreviousMonthPropertyName: 'impressionsPreviousMonth',
      metricPreviousMTDPropertyName: 'impressionsPreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'impressionsMOM',
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      helpTextKey: 'DigAdOverviewImpressionsBillboardHelp',
      helpTextTitle: 'Impressions',
      showTrendArrow: true
    },
    {
      title: 'Click Through Rate',
      subtitle: null,
      subtitleOne: 'clickThroughRateMTD',
      subtitleTwo: 'clickThroughRatePreviousMonth',
      iconClass: 'fa-eye fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'clickThroughRate',
      metricMOMPropertyName: 'clickThroughRateMOM',
      metricYOYPropertyName: 'clickThroughRateYOY',
      metricPreviousMonthPropertyName: 'clickThroughRatePreviousMonth',
      metricPreviousMTDPropertyName: 'clickThroughRatePreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'clickThroughRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'DigAdOverviewClickThroughRateBillboardHelp',
      helpTextTitle: 'Click Through Rate',
      showTrendArrow: true
    },
    {
      title: 'Total Visits',
      subtitle: null,
      subtitleOne: 'visitsPreviousMTD',
      subtitleTwo: 'visitsPreviousMonth',
      iconClass: 'fa-users fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'visits',
      metricMOMPropertyName: 'visitsMOM',
      metricYOYPropertyName: 'visitsYOY',
      metricPreviousMonthPropertyName: 'visitsPreviousMonth',
      metricPreviousMTDPropertyName: 'visitsPreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'visitsMOM',
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      helpTextKey: 'DigAdOverviewTotalVisitsBillboardHelp',
      helpTextTitle: 'Total Visits',
      showTrendArrow: true
    },
    {
      title: 'Conversion Rate',
      subtitle: null,
      subtitleOne: 'conversionRatePreviousMTD',
      subtitleTwo: 'conversionRatePreviousMonth',
      iconClass: 'fa-mouse-pointer fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'conversionRate',
      metricMOMPropertyName: 'conversionRateMOM',
      metricYOYPropertyName: 'conversionRateYOY',
      metricPreviousMonthPropertyName: 'conversionRatePreviousMonth',
      metricPreviousMTDPropertyName: 'conversionRatePreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'conversionRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'DigAdOverviewConversionRateBillboardHelp',
      helpTextTitle: 'Conversion Rate',
      showTrendArrow: true
    },
  ];

  public kpiSheetOptions = {
    overview: [{
      name: 'displayName',
      label: '',
      isHeader: true
    }, {
      name: 'impressions',
      label: 'Impressions',
      format: constants.formatKeys.localeStringOrNa
    }, {
      name: 'clickThroughRate',
      label: 'Click Through Rate',
      format: constants.formatKeys.percentageTwoDecimals
      }, {
        name: 'visits',
        label: 'Total Visits',
        format: constants.formatKeys.localeStringOrNa
      }, {
      name: 'engagementRate',
      label: 'Engagement Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'conversionRate',
      label: 'Conversion Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'salesServiceOtherBounces',
      label: 'Sales | Service | Other | Bounces',      
    }]
  };

  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    // Impressions
    {
      show: true,
      header: 'Impressions',
      columnDef: 'impressions',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Clicks
    {
      show: true,
      header: 'Clicks',
      columnDef: 'clicks',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Click Through Rate
    {
      show: true,
      header: 'Click Through Rate',
      columnDef: 'clickThroughRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Cost per Click
    {
      show: true,
      header: 'Cost Per Click',
      columnDef: 'costPerClick',
      metricFormatKey: constants.formatKeys.currency,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Cost per Impressions
    {
      show: true,
      header: 'Cost Per Impression',
      columnDef: 'costPerImpression',
      metricFormatKey: constants.formatKeys.currency,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Total Vists
    {
      show: true,
      header: 'Total Visits',
      columnDef: 'visits',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Unique Visits
    {
      show: true,
      header: 'Unique Visits',
      columnDef: 'uniqueVisitors',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Form Submissions
    {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Phone Calls
    {
      show: true,
      header: 'Phone Calls',
      columnDef: 'phoneLeads',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },

  ];
  public dealerBenchmarkLookbackColumns?: IDefaultTableColumnConfig[] = [];
  public kpiCardConfig: IDigAdOverviewKpiConfig = {
    chartTitle: 'Total Visits vs. Engaged Visits',
    preCutoffChartTitle: 'Total Visits vs. Unique Visits',
    xAxisPropertyName: 'date',
    metrics: {
      'MetricOne': {
          yAxisTitle: 'Visits',
          displayName: 'Total Visits',
          pointLabelFormat: null,
          propertyName: 'visits',
          metricFormatKey: null,
          formatter: null
      },
      'MetricTwo': {
          yAxisTitle: 'Visits',
          displayName: 'Engaged Visits',
          pointLabelFormat: null,
          propertyName: 'visitsWithEngagements',
          metricFormatKey: null,
          formatter: null
      },
      'PreCutoffMetricTwo': {
          yAxisTitle: 'Visits',
          displayName: 'Unique Visits',
          pointLabelFormat: null,
          propertyName: 'uniqueVisitors',
          metricFormatKey: null,
          formatter: null
      },
      'PreCutoffMetricOne': {
        yAxisTitle: 'Visits',
        displayName: 'Total Visits',
        pointLabelFormat: null,
        propertyName: 'visits',
        metricFormatKey: null,
        formatter: null
      }
    }
  };
}
