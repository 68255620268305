export class SharedTranslationConfig {
    public labels = {
      'fr-CA': {
        // orgs - maybe split out
        entity: 'Entité',
        sales: 'Ventes',
        service: 'Service',
        keymarkets: 'Marchés clés',
        central: 'Centrale',
        eastern: 'Est',
        western: 'Ouest',
        national: 'National',
        region: 'Région',
        // filter translations
        filter: 'Filtre',
        filters: 'Filtres',
        provider: 'Fournisseur',
        supplier: 'Fournisseur',
        devicetype: 'Type d\'appareil',
        channel: 'Chaîne',
        desktop: 'Bureau',
        tablet: 'Tablette',
        mobile: 'Mobile',
        phone: 'Mobile',
        // common labels
        general: 'Générale',
        default: 'Défaut',
        mom: 'MSM',
        yoy: 'ASA',
        trend: 'Teaux',
        providerkpi: 'Fourniesseur KPI',
        thismonth: 'Mois courant',
        prevmonth: 'Mois préc.',
        prevyear: 'Année préc.',
        prevmtd: 'Màj préc.',
        help: 'Aide',
        exportall: 'Exporter tout',
        summarydata: 'Résumé Des Données',
        dealerperformance: 'Rendement du concessionnaire',
        dealeravg: 'Concessionnaire moy',
        // filter panel labels
        allmodels: 'Tous les modèles',
        selected: 'sélectionné',
        search: 'Chercher',
        clearselected: 'EFFACER' + '\n' + 'SÉLECTIONNÉ',
        noneselected: 'Aucune sélection',
        apply: 'Appliquer',
        more: 'plus',
        cancel: 'Annuler',
        // dates
        start: 'Début',
        end: 'Fin',
        days: 'Journées',
        custom: 'Personnalisé',
        currentmonth: 'Mois courant',
        lastmonth: 'Mois précédent',
        last30days: '30 derniers Jours',
        last3months: '3 Mois precédent',
        last12months: '12 Derniers mois',
        quarters: 'Quarts',
        salesmonthsenabled: 'Mois de vente activés',
        january: 'Janvier',
        december: 'Décembre',
        november: 'Novembre',
        february: 'Février',
        march: 'Mars',
        april: 'Avril',
        may: 'Mai',
        june: 'Juin',
        july: 'Juillet',
        august: 'Aout',
        september: 'Septembre',
        october: 'Octobre',

        new: 'Nouveau',
        cpo: 'CPO',
        used: 'Ventes D\'occasion',
        dealersummary: 'Sommaire du Concessionnaire',
        dealtype: 'Type de Transaction',
        vehicletype: 'Type de Vehicule',
        providerkpiexcel: 'KPI.s  Fournisseur  sur Excel',
        total: 'Total',
        other: 'Autre',
        finance: 'Financement',
        lease: 'Location',
        cash: 'Comptant'
      },

  };
}
