export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'Lexus-CA',
  hostName: 'https://analytics-staging.lexusdds.ca',
  environmentName: 'staging',
  appName: 'Lexus CA DDS Insight',
  applicationInsights: {
    instrumentationKey: '82697b4f-44ef-4612-bf4f-e3b5f43bf213'
  },
  defaultLocale: 'en',
  availableLocales: ['en', 'fr-CA'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'false',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  ssoPassword: 'ShiftDigital#123',
  defaultChartColors: ['#000', '#666666', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://lexusca-analytics-api-staging.azurewebsites.net/api',
  authUri: 'https://lexusca-analytics-api-staging.azurewebsites.net/token',
  baseV5ApiUri: 'https://lexusca-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/lexus-ca/favicon.ico',
  loginRedirectUrl: 'https://tcihub.oktapreview.com/home/tcihub_ddslexusinsight_1/0oad4p1bofSh7xxWD1d7/alnd4p9g7mKSg1VBB1d7',
  logoutRedirectUrl: 'https://tcihub.oktapreview.com/home/tcihub_ddslexusinsight_1/0oad4p1bofSh7xxWD1d7/alnd4p9g7mKSg1VBB1d7',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['WebsiteOverview', 'DigitalAdvertisingDashboard']
};
